<style scoped>
/* /deep/ .el-autocomplete-suggestion {
  width: auto!important;
}
.el-select > .el-select-dropdown {
  transform:translateX(-100px);
} */

/deep/.el-autocomplete-suggestion {
  position: absolute !important;
  top: 50px !important;
  left: 0 !important;
  width: auto !important;
}

/deep/ .c-panel .el-form .el-input {
  width: 217px;
}
</style>

<template>
  <div class="vue-box">
    <!-- 参数栏 -->
    <div class="c-panel">
      <el-page-header v-if="active !=0" :title="'重新创建申请单'" @back="reCreate" content="进件申请">
      </el-page-header>
      <el-steps :active="active" style="margin-top: 20px" finish-status="success" simple>
        <el-step title="创建申请" icon="el-icon-edit">创建申请单</el-step>
        <el-step title="主体信息" icon="el-icon-edit">填写主体信息</el-step>
        <el-step title="店铺信息" icon="el-icon-edit">填写店铺信息</el-step>
        <el-step title="结算信息" icon="el-icon-edit">填写结算信息</el-step>
        <el-step title="预览" icon="el-icon-picture">预览</el-step>
      </el-steps>

      <el-row type="flex" justify="center" align="middle">
        <el-form style="margin-top: 50px; width: 350px" size="medium" v-if="active == 0" label-width="100px">
          <el-form-item label="主体类型:">
            <el-select v-model="step1.applyType">
              <el-option label="小微商户" :value="2401"></el-option>
              <el-option label="个人卖家" :value="2500"></el-option>
              <el-option label="个体工商户" :value="4"></el-option>
              <el-option label="企业" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="服务商选择:" placeholder="请选择">
            <el-select class="select_width" v-model="step1.provider_id" size="small" @click.native="getServerProvider()">
              <el-option v-for="item in providerlists" :key="item.providerName" :label="item.providerName" :value="item.providerId" />
            </el-select>
          </el-form-item>

          <el-form-item label="操作密码:">
            <el-input show-password v-model="step1.provider_password" placeholder="填写服务商操作密码"></el-input>
          </el-form-item>

          <el-form-item>
            <span class="c-label"><el-button style="margin-top: 12px" @click="setpr1()">下一步</el-button></span>
          </el-form-item>
        </el-form>

        <el-form style="margin-top: 50px" size="medium" v-if="isShow(1, 1)" label-width="120px">
          <el-form-item label="营业执照注册号:">
            <el-input style="width: 350px" v-model="step2.registrNumber" placeholder="营业执照上的注册号/统一社会信用代码"></el-input>
          </el-form-item>
          <el-form-item label="商户名称:">
            <el-input v-model="step2.mchName" style="width: 350px" placeholder="请填写营业执照/登记证书的商家名称"></el-input>
          </el-form-item>

          <el-form-item label="营业执照上传">
            <el-upload class="upload-demo" drag :data="p" :action="this.sa.cfg.provider_api_url + '/apply/uploadImage'" :on-success="handleBusinessImgIdSuccess" multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="身份证姓名:">
            <el-input style="width: 350px" v-model="step2.idName" placeholder="请填写身份证姓名"></el-input>
          </el-form-item>

          <el-form-item label="身份证号码:">
            <el-input style="width: 350px" v-model="step2.idNumber" placeholder="请填写身份证号码"></el-input>
          </el-form-item>

          <el-form-item v-if="step1.applyType == 2" label="证件居住地址:">
            <el-input style="width: 350px" v-model="step2.idAddress" placeholder="请填写证件居住地址"></el-input>
          </el-form-item>

          <el-form-item label="身份证人像面">
            <el-upload class="upload-demo" drag :data="p" :action="this.sa.cfg.provider_api_url + '/apply/uploadImage'" :on-success="handleIdFaceImgIdSuccess" multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="身份证国徽面">
            <el-upload class="upload-demo" drag :data="p" :action="this.sa.cfg.provider_api_url + '/apply/uploadImage'" :on-success="handleIdCountryImgIdSuccess" multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="证件开始日期">
            <el-date-picker v-model="step2.idCreatedTime" value-format="yyyy-MM-dd" type="date" placeholder="证件开始日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="证件结束日期">
            <el-date-picker v-model="step2.idLostTime" value-format="yyyy-MM-dd" type="date" placeholder="证件结束日期">
            </el-date-picker>
            <br>
            <el-input style="width: 350px" v-model="step2.idLostTime" placeholder="日期"></el-input>
          </el-form-item>
          <el-form-item>
            <span class="c-label">
              <el-button style="margin-top: 12px" @click="saveStep2">保存,下一步</el-button></span>
          </el-form-item>
        </el-form>

        <el-form style="margin-top: 50px" size="medium" v-if="isShow(2, 2)" label-width="120px">
          <el-form-item label="商户简称:">
            <el-input style="width: 350px" v-model="step3.mchAbbreviation" placeholder="将在支付完成页向买家展示，需与商家的实际售卖商品相符"></el-input>
          </el-form-item>

          <el-form-item label="店铺名称:">
            <el-input v-model="step3.storeName" style="width: 350px" placeholder="店铺名称"></el-input>
          </el-form-item>

          <el-form-item label="店铺链接:">
            <el-input v-model="step3.storeUrl" style="width: 350px" placeholder="店铺链接"></el-input>
          </el-form-item>

          <el-form-item label="超级管理员邮箱:">
            <el-input v-model="step3.contactEmail" style="width: 350px" placeholder="超级管理员邮箱"></el-input>
          </el-form-item>

          <el-form-item label="超级管理员电话:">
            <el-input v-model="step3.serviceNumber" style="width: 350px" placeholder="超级管理员电话"></el-input>
          </el-form-item>

          <el-form-item>
            <span class="c-label"><el-button style="margin-top: 12px" @click="up()">上一步</el-button></span>
            <span class="c-label">
              <el-button style="margin-top: 12px" @click="saveStep3">保存,下一步</el-button></span>
          </el-form-item>
        </el-form>

        <el-form style="margin-top: 50px" ref="form" v-if="isShow(3, 3)" label-width="140px">
          <el-form-item label="账户类型:">
            <el-select @change="clearBank" v-model="step4.accountType">
              <el-option label="对私账户" :value="75"></el-option>
              <el-option label="对公账户" :value="74"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="开户银行:">
            <el-select filterable v-el-select-loadmore="loadmore" v-model="step4.bankName" @change="changeBank">
              <el-option v-for="item in banklist" :key="item.bank_alias" :label="item.bank_alias" :value="item.bank_alias + '|' + item.need_bank_branch" />
            </el-select>
          </el-form-item>

          <el-form-item v-if="need_bank_branch == 'true'" label="开户银行全称:">
            <el-input v-model="step4.bankSubName" style="width: 100%" placeholder="开户银行全称"></el-input>
          </el-form-item>
          <el-row>
            <el-form-item label="开户银行省市编码:">
              <el-autocomplete v-model="keyword" :popper-append-to-body="false" :fetch-suggestions="querySearchAsyncAddressCode" popper-class="el-autocomplete-suggestion" placeholder="请输入地区搜索并选择" @select="handleSelect" :trigger-on-focus="false"></el-autocomplete>
            </el-form-item>
          </el-row>
          <el-form-item label="银行账号:">
            <el-input v-model="step4.bankUsername" style="width: 350px" placeholder="银行账号"></el-input>
          </el-form-item>

          <el-form-item>
            <span class="c-label"><el-button style="margin-top: 12px" @click="up()">上一步</el-button></span>
            <span class="c-label">
              <el-button style="margin-top: 12px" @click="saveStep4">保存,下一步</el-button></span>
          </el-form-item>
        </el-form>

        <el-form v-if="isShow(4, 4)" style="margin-top: 50px" size="medium" label-width="140px">
          <el-form-item label="身份证姓名:">
            <p>{{ this.step2.idName }}</p>
          </el-form-item>
          <el-form-item label="身份证号:">
            <p>{{ this.step2.idNumber }}</p>
          </el-form-item>
          <el-form-item label="营业执照注册号:">
            <p>{{ this.step2.registrNumber }}</p>
          </el-form-item>
          <el-form-item label="商家名称:">
            <p>{{ this.step2.mchName }}</p>
          </el-form-item>
          <el-form-item label="身份证生效日期:">
            <p>{{ this.step2.idCreatedTime }}</p>
          </el-form-item>
          <el-form-item label="身份证失效日期:">
            <p>{{ this.step2.idLostTime }}</p>
          </el-form-item>
          <el-form-item label="商户简称:">
            <p>{{ this.step3.mchAbbreviation }}</p>
          </el-form-item>
          <el-form-item label="店铺链接:">
            <p>{{ this.step3.storeUrl }}</p>
          </el-form-item>
          <el-form-item label="账户类型:">
            <p>{{ this.step4.accountType }}</p>
          </el-form-item>
          <el-form-item label="开户银行:">
            <p>{{ this.step4.bankName }}</p>
          </el-form-item>
          <el-form-item label="开户银行全称:">
            <p>{{ this.step4.bankSubName }}</p>
          </el-form-item>
          <el-form-item label="开户银行省市编码:">
            <p>{{ this.step4.addressCode }}</p>
          </el-form-item>
          <el-form-item label="银行账号:">
            <p>{{ this.step4.bankUsername }}</p>
          </el-form-item>
          <el-form-item label="操作密码:">
            <el-input show-password v-model="step1.provider_password" placeholder="填写服务商操作密码"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button style="margin-top: 12px" @click="up()">上一步</el-button>
            <el-button type="primary" style="margin-top: 12px" @click="ok">提交</el-button>
            <el-button type="primary" style="margin-top: 12px" @click="batchApply">批量提交</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      applyId: "",
      active: 0,
      providerlists: [],
      bankoffset: 0,
      banklist: [],
      addresslist: [],
      keyword: "",
      timeout: null,
      need_bank_branch: true,
      p: {
        provider_id: "",
      },
      //第一步所需参数
      step1: {
        applyType: 4,
        provider_id: "",
        code_mch_id: "",
        code_nick_name: "",
        provider_password: "",
      },
      step2: {
        applyId: "",
        businessImgId: "",
        registrNumber: "",
        mchName: "",
        idFaceImgId: "",
        idCountryImgId: "",
        idName: "",
        idNumber: "",
        idCreatedTime: "",
        idLostTime: "",
        idAddress: "",
        isSyr: 1,
      },
      step3: {
        applyId: "",
        mchAbbreviation: "",
        serviceNumber: "",
        storeUrl: "",
        storeName: "",
        contactEmail: "",
      },
      step4: {
        applyId: "",
        accountType: "",
        bankName: "",
        bankSubName: "",
        addressCode: "",
        bankUsername: "",
      },
    };
  },
  methods: {
    ok: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var page = {
        applyId: this.applyId,
        provider_password: this.step1.provider_password,
      };
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/apply/submit",
        page,
        function () {
          this.active = 0;
          this.sa.setCookie("applyment_id", null, 0);
          this.cleanData(this.step1);
          this.cleanData(this.step2);
          this.cleanData(this.step3);
          this.cleanData(this.step4);
        }.bind(this),
        defaultCfg
      );
    },
    batchApply: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      let self = this;
      this.sa.prompt(
        "要批量提交的数量",
        function (value) {
          var page = {
            applyId: self.applyId,
            batchNum: value,
            provider_password: self.step1.provider_password,
          };
          self.sa.ajax(
            self.sa.cfg.provider_api_url + "/apply/batchApply",
            page,
            function () {
              self.active = 0;
              self.sa.setCookie("applyment_id", null, 0);
              self.cleanData(this.step1);
              self.cleanData(this.step2);
              self.cleanData(this.step3);
              self.cleanData(this.step4);
            }.bind(self),
            defaultCfg
          );
        },
        0
      );
    },
    cleanData: function (data) {
      Object.keys(data).forEach((key) => {
        data[key] = "";
      });
    },
    isShow(i, j) {
      if (i == this.active || j == this.active) {
        return true;
      } else {
        return false;
      }
    },
    //创建申请单
    setpr1: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      this.step1.code_mch_id = this.sa_admin.mchId;
      this.step1.code_nick_name = this.sa_admin.nickName;
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/apply/create",
        this.step1,
        function (res) {
          this.applyId = res.data;
          this.p.provider_id = this.step1.provider_id;
          this.sa.setCookie("applyment_id", res.data, 2);
          this.next();
        }.bind(this),
        defaultCfg
      );
    },
    next: function () {
      if (this.active++ > 4) this.active = 1;
    },
    up: function () {
      this.active--;
    },
    getServerProvider: function () {
      var defaultCfg = {
        type: "get", //是否是请求体请求
        sleep: 50,
        msg: null,
      };
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/provider/list",
        function (res) {
          this.providerlists = res.data.records; // 数据
        }.bind(this),
        defaultCfg
      );
    },
    reCreate: function () {
      this.active = 0;
      this.sa.setCookie("applyment_id", null, 0);
      this.cleanData(this.step1);
      this.cleanData(this.step2);
      this.cleanData(this.step3);
      this.cleanData(this.step4);
    },
    saveStep2: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      this.step2.applyId = this.applyId;
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/apply/save_subject_info",
        this.step2,
        function (res) {
          console.log(res);
          this.next();
        }.bind(this),
        defaultCfg
      );
    },
    saveStep3: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      this.step3.applyId = this.applyId;
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/apply/save_business_info",
        this.step3,
        function (res) {
          console.log(res);
          this.next();
        }.bind(this),
        defaultCfg
      );
    },
    saveStep4: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      console.log(this.step4);
      this.step4.applyId = this.applyId;
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/apply/save_bank_account_info",
        this.step4,
        function (res) {
          console.log(res);
          this.next();
        }.bind(this),
        defaultCfg
      );
    },
    changeBank: function (data) {
      let [name, need_bank_branch] = data.split("|");
      this.step4.bankName = name;
      this.need_bank_branch = need_bank_branch;
    },
    handleBusinessImgIdSuccess: function (res) {
      if (res.code != 200) {
        this.sa.error(res.msg)
        return;
      }
      var str = JSON.parse(res.data);
      this.step2.businessImgId = str.media_id;
      console.log(this.step2);
    },
    handleIdFaceImgIdSuccess: function (res) {
      if (res.code != 200) {
        this.sa.error(res.msg)
        return;
      }
      var str = JSON.parse(res.data);
      this.step2.idFaceImgId = str.media_id;
      console.log(this.step2);
    },
    handleIdCountryImgIdSuccess: function (res) {
      if (res.code != 200) {
        this.sa.error(res.msg)
        return;
      }
      var str = JSON.parse(res.data);
      this.step2.idCountryImgId = str.media_id;
      console.log(this.step2);
    },
    querySearchBankName: function (offset) {
      var defaultCfg = {
        type: "get",
        msg: null,
        sleep: 50,
      };
      var page = {
        limit: 10,
        offset: offset,
      };
      let url = null;
      if (this.step4.accountType == 74) {
        url = this.sa.cfg.provider_api_url + "/bank/corporate-banking";
      } else {
        url = this.sa.cfg.provider_api_url + "/bank/personal-banking";
      }
      this.sa.ajax(
        url,
        page,
        function (res) {
          var data = JSON.parse(res.data);
          console.log(data);
          this.sa.pushArray(this.banklist, data.data);
        }.bind(this),
        defaultCfg
      );
    },
    querySearchAsyncAddressCode: function (queryString, cb) {
      var defaultCfg = {
        type: "get",
        msg: null,
        sleep: 50,
      };
      var page = {
        address: queryString,
      };
      if (this.keyWord != "") {
        this.sa.ajax(
          this.sa.cfg.provider_api_url + "/bank/addressCode",
          page,
          function (res) {
            console.log(res);
            let addresslist = res.data;
            addresslist = addresslist.map((item) => {
              return { value: item.address, id: item.id };
            });
            cb(addresslist);
          }.bind(this),
          defaultCfg
        );
      }
    },
    async handleSelect(val) {
      console.log(val);
      this.step4.addressCode = val.id;
    },
    intApply: function () {
      var defaultCfg = {
        type: "get", //是否是请求体请求
        sleep: 50,
        msg: null,
      };
      //从cookie里获取申请单
      var p = {
        apply_id: this.sa.getCookie("applyment_id"),
      };
      if (this.sa.isNull(p.apply_id)) {
        return;
      }
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/apply/detail",
        p,
        function (res) {
          var data = res.data;
          console.log(res.data);
          this.applyId = this.sa.getCookie("applyment_id");
          if (data.subjectInfoStatus == 0) {
            this.active = 1;
          } else if (data.businessInfoStatus == 0) {
            this.active = 2;
          } else if (data.bankAccountStatus == 0) {
            this.active = 3;
          } else {
            this.active = 4;
            this.getApplyDetail();
          }
          this.p.provider_id = data.providerMch;
          console.log(this.p.provider_id);
        }.bind(this),
        defaultCfg
      );
    },
    loadmore() {
      this.bankoffset = this.bankoffset + 10;
      this.querySearchBankName(this.bankoffset);
    },
    clearBank() {
      this.banklist = [];
      this.querySearchBankName(0);
      this.step4.bankName = "";
      this.$forceUpdate();
    },
    getApplyDetail() {
      var defaultCfg = {
        type: "get", //是否是请求体请求
        msg: null,
      };
      this.sa.ajax(
        this.sa.cfg.provider_api_url +
        "/apply/getApplyInfoDetail/" +
        this.applyId,
        function (res) {
          this.step2.idName = res.data.subjectInfo.idName;
          this.step2.idNumber = res.data.subjectInfo.idNumber;
          this.step2.idCountryImgId = res.data.subjectInfo.idCountryImgId;
          this.step2.idFaceImgId = res.data.subjectInfo.idFaceImgId;
          this.step2.businessImgId = res.data.subjectInfo.businessImgId;
          this.step2.idAddress = res.data.subjectInfo.idAddress;
          this.step2.idCreatedTime = res.data.subjectInfo.idCreatedTime;
          this.step2.idLostTime = res.data.subjectInfo.idLostTime;
          this.step2.mchName = res.data.subjectInfo.mchName;
          this.step2.registrNumber = res.data.subjectInfo.registrNumber;
          this.step3.mchAbbreviation = res.data.businessInfo.mchAbbreviation;
          this.step3.storeUrl = res.data.businessInfo.storeUrl;
          this.step3.storeName = res.data.businessInfo.storeName;
          this.step3.contactEmail = res.data.businessInfo.contactEmail;
          this.step3.serviceNumber = res.data.businessInfo.serviceNumber;
          this.step4.accountType = res.data.bankAccountInfo.accountType;
          this.step4.bankName = res.data.bankAccountInfo.bankName;
          this.step4.bankSubName = res.data.bankAccountInfo.bankSubName;
          this.step4.bankUsername = res.data.bankAccountInfo.bankUsername;
          this.step4.addressCode = res.data.bankAccountInfo.addressCode;
        }.bind(this),
        defaultCfg
      );
    },
  },
  created() {
    this.intApply();
  },
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
};
</script>


